const SubscriberQualitiesEnum = {
    DEMI_PENSIONNAIRE: "DEMI_PENSIONNAIRE",
    INTERNE: "INTERNE",
    ...(window._env_.REACT_APP_PROJECT !== "RNA" ? {EXTERNE: "EXTERNE"} : {}),
};

/**
 * @readonly
 * @enum {string}
 */
const SubscriberResidenceTypeEnum = {
    DUAL_RESIDENCE: "DUAL_RESIDENCE",
    DUAL_RESIDENCE_1_TRANSPORT: "DUAL_RESIDENCE_1_TRANSPORT",
    FOSTER_FAMILY: "FOSTER_FAMILY",
    DROP_IN_CENTER: "DROP_IN_CENTER",
    SIMPLE_RESIDENCE: "SIMPLE_RESIDENCE",

    isFosterChild(residenceType) {
        return [
            SubscriberResidenceTypeEnum.FOSTER_FAMILY,
            SubscriberResidenceTypeEnum.DROP_IN_CENTER
        ].includes(residenceType);
    }
};

const SubscriptionCompletedByLegalRepresentativeEnum = {
    NOT_COMPLETED: "NOT_COMPLETED",
    PARTLY_COMPLETED: "PARTLY_COMPLETED",
    FULL_COMPLETED: "FULLY_COMPLETED",
};

/**
 *
 * @type {{[SubscriberResidenceTypeEnum]: string}}
 */
const SubscriberResidenceTypeLabel = {
    DUAL_RESIDENCE: "Double résidence - Deux transports",
    DUAL_RESIDENCE_1_TRANSPORT: "Double résidence - Un seul transport",
    FOSTER_FAMILY: "Famille d'accueil",
    DROP_IN_CENTER: "Centre d'accueil",
    SIMPLE_RESIDENCE: "Simple résidence",
};

const InstitutionOptionTypesEnum = {
    LV1: "LV1",
    LV2: "LV2",
    OPTION: "OPTION",
    SPECIALITY: "SPECIALITY",
    SECTION: "SECTION",
};

const WishParticularCasesEnum = {
    SNCF: "SNCF",
    COMBINED_TICKET: "COMBINED_TICKET",
};

const LR2WishValidationStatusEnum = {
    VALIDATED: "VALIDATED",
    REFUSED: "REFUSED",
    ASK_FOR_ANOTHER_WISH: "ASK_FOR_ANOTHER_WISH",
    WAITING_FOR_VALIDATION: "WAITING_FOR_VALIDATION",
    ASK_FOR_REFUSED: "ASK_FOR_REFUSED",
};

const CombinedTicketEnum = {
    DETECTED: "DETECTED",
    WISHED: "WISHED",
    ACCEPTED: "ACCEPTED",
    REFUSED: "REFUSED"
};

const NotTrusteeReasonEnum = {
    HOME_TOO_CLOSE: "HOME_TOO_CLOSE",
    OUT_OF_SCHOOL_MAP: "OUT_OF_SCHOOL_MAP",
    PAID_APPRENTICE: "PAID_APPRENTICE",
    APPRENTICE: "APPRENTICE",
    UNPAID_APPRENTICE: "UNPAID_APPRENTICE",
    STUDENT: "STUDENT",
    NOT_CALCULATED: "NOT_CALCULATED",
};

const RegistrationStatusEnum = {
    WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
    REFUSED: "REFUSED",
    WAITING_FOR_EXAMINATION: "WAITING_FOR_EXAMINATION",
    WAITING_FOR_ASSIGNMENT: "WAITING_FOR_ASSIGNMENT",
    PRE_ASSIGNED: "PRE_ASSIGNED",
    FULLY_ASSIGNED: "FULLY_ASSIGNED",
    ACTIVATED: "ACTIVATED",
    WAITING_FOR_RENEWAL: "WAITING_FOR_RENEWAL",
    ARCHIVED: "ARCHIVED",
    SUBSCRIPTION_IN_PROGRESS: "SUBSCRIPTION_IN_PROGRESS",
    WAITING_FOR_DOCUMENTS: "WAITING_FOR_DOCUMENTS",
    WAITING_FOR_SUBSCRIPTIONS_CHANGES: "WAITING_FOR_SUBSCRIPTIONS_CHANGES",
    CANCELED: "CANCELED",
};

const ConstraintUsageType = {
    MIXED: "MIXED",
    INTERN: "INTERN",
    HALF_BOARDER: "HALF_BOARDER"
}; // Gestion pour les requêtes OTP

const TransportTicketDuplicateStatus = {
    WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
    ASKED: "ASKED",
    VALIDATED: "VALIDATED"
};

const MAILING_CATEGORY_NAME_DISTURBANCE = "Information voyageur";

const MAILING_CATEGORY_NAME_EMAIL = "Actualités";

const MailingWishEnum = {
    BOTH: "BOTH",
    SMS: "SMS",
    MAIL: "MAIL",
    NONE: "NONE",

    getMailingWishExactValueForSMS: (fromMailingWishes, concernedMailingCategory, newValue) => {
        const previousValue = fromMailingWishes[concernedMailingCategory];

        switch (previousValue) {
            case MailingWishEnum.MAIL:
                if (newValue) {
                    return MailingWishEnum.BOTH;
                }
                break;

            case MailingWishEnum.SMS:
                if (!newValue) {
                    return MailingWishEnum.NONE;
                }
                break;

            case MailingWishEnum.BOTH:
                if (!newValue) {
                    return MailingWishEnum.MAIL;
                }
                break;

            case MailingWishEnum.NONE:
            default:
                if (newValue) {
                    return MailingWishEnum.SMS;
                }
                break;
        }

        return previousValue;
    },

    getMailingWishExactValueForMail: (fromMailingWishes, concernedMailingCategory, newValue) => {
        const previousValue = fromMailingWishes[concernedMailingCategory];

        switch (previousValue) {
            case MailingWishEnum.MAIL:
                if (!newValue) {
                    return MailingWishEnum.NONE;
                }
                break;
            case MailingWishEnum.SMS:
                if (newValue) {
                    return MailingWishEnum.BOTH;
                }
                break;
            case MailingWishEnum.BOTH:
                if (!newValue) {
                    return MailingWishEnum.SMS;
                }
                break;
            case MailingWishEnum.NONE:
            default:
                if (newValue) {
                    return MailingWishEnum.MAIL;
                }
                break;
        }

        return previousValue;
    },
};

const StepCodeRNA = {
    Step1RNA: "Step1RNA",
    Step2RNA: "Step2RNA",
    Step3RNA: "Step3RNA",
    Step4RNA: "Step4RNA",

    stepTraduction: (step) => {
        switch (step) {
            case StepCodeRNA.Step1RNA:
                return "Étape 1";
            case StepCodeRNA.Step2RNA:
                return "Étape 2";
            case StepCodeRNA.Step3RNA:
                return "Étape 3";
            case StepCodeRNA.Step4RNA:
                return "Étape 4";
        }
        return "";
    }
};

export {
    SubscriberQualitiesEnum,
    SubscriberResidenceTypeEnum,
    SubscriptionCompletedByLegalRepresentativeEnum,
    SubscriberResidenceTypeLabel,
    InstitutionOptionTypesEnum,
    WishParticularCasesEnum,
    NotTrusteeReasonEnum,
    RegistrationStatusEnum,
    CombinedTicketEnum,
    ConstraintUsageType,
    TransportTicketDuplicateStatus,
    LR2WishValidationStatusEnum,
    MAILING_CATEGORY_NAME_DISTURBANCE,
    MAILING_CATEGORY_NAME_EMAIL,
    MailingWishEnum,
    StepCodeRNA
};
