import React from "react";
import {AlertColor, Card, CardActions, CardContent, ListItem, Typography} from "@mui/material";
import LinkWithIcon from "@/components/common/ui/LinkWithIcon";
import SubscriberService, {SubscriberRequest} from "@/service/SubscriberService";

import {getSubscriptionDetailRoute} from "@/Routes";
import StatusBox from "@/components/common/ui/StatusBox";
import UserPicture from "@/components/common/UserPicture";

export type ListSubscribersRegisteredItemProps = {
    subscriberRequest: SubscriberRequest
}

const ListSubscribersRegisteredItem = ({
    subscriberRequest,
}: ListSubscribersRegisteredItemProps) => {

    const subscriber = subscriberRequest.subscriberInfo || subscriberRequest.content.subscriber;

    const statusInfo = SubscriberService.getRegistrationStatus(subscriberRequest.status || "", subscriberRequest.completedByLegalRepresentative);

    const isClientBRE = window._env_.REACT_APP_PROJECT === "BRE";

    return (
        <ListItem key={subscriberRequest.uuid}>
            <Card variant="outlined" style={{width: "100%"}}>
                <StatusBox
                    style={{
                        float: "right",
                        borderRadius: "0 0 0 4px",
                    }}
                    color={statusInfo.variant as AlertColor}
                    label={statusInfo.label}
                />
                <CardContent style={{display: "flex"}}>
                    <UserPicture
                        isValidPhoto={subscriberRequest.content.subscriber.validPhoto || !!subscriberRequest.subscriberId}
                        subscriberRequestUUID={subscriberRequest.uuid}/>
                    <p className="subscription-list-name">
                        {subscriber.firstName}
                        <br/>
                        {subscriber.lastName}
                        <br/>
                        {isClientBRE && subscriberRequest.subscriberInfo?.id && (
                            <Typography variant="body2">Dossier
                                #{subscriberRequest.subscriberInfo.id}
                            </Typography>
                        )}
                    </p>
                </CardContent>
                <CardActions sx={{
                    display: "flex",
                    justifyContent: "end",
                    backgroundColor: (theme) => theme.colors.lightBackground,
                    marginTop: "-30px",
                }}>
                    <LinkWithIcon sx={{m: 0}} to={{pathname: getSubscriptionDetailRoute(subscriberRequest.uuid)}}>
                        Voir le détail
                    </LinkWithIcon>
                </CardActions>
            </Card>
        </ListItem>
    );
};

export default ListSubscribersRegisteredItem;